import * as React from 'react'
import * as Rebass from 'rebass'
import styled from '@emotion/styled'
import { FastField, Field, FieldArray, Formik, yupToFormErrors } from 'formik'
import * as Yup from 'yup'
// import MaskedInput from 'react-text-mask';
import SignatureCanvas from 'react-signature-canvas'
import axios, { AxiosRequestConfig } from 'axios'
import { Link as RouterLink, GatsbyLinkProps, navigate } from 'gatsby'
import { LinkProps } from '@reach/router'
import ReactInputMask from 'react-input-mask'

import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import * as Color from 'color'
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  makeStyles,
  createStyles,
  Theme,
  WithStyles
} from '@material-ui/core/styles'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

import Amplify, { Auth } from 'aws-amplify'

import aws4 from 'aws4'

import { AppointmentReason } from './appointment-reason.en';
import { MenuContext } from '../../../contexts/menu-context';

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:d8c6ec12-4b8a-45d9-a624-e939c511d8fa',
    region: 'us-east-1',
    userPoolId: 'us-east-1_Lbu0RBnOL',
    userPoolWebClientId: '58nk7vvnol6pbl3o6l8vag7ae7'
  }
})

import { Layout } from '../../../components/layout'
import SEO from '../../../components/seo'
import ErrorFocus from '../../../components/error-focus'
import { withStyles } from '@material-ui/styles'

// const AddIcon = require('../../../assets/icons/ic_add_circle_outline_24px.svg')
// const DelIcon = require('../../../assets/icons/ic_remove_circle_outline_24px.svg')

const LinkComponent = React.forwardRef<
  HTMLAnchorElement,
  Omit<GatsbyLinkProps<{}>, 'ref'>
>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

const BACKGROUND_COLOR = '#F5F7FA'
const BORDER_COLOR = '#E4E7EB'

const HR = styled('div')({
  height: '1px',
  width: '100%',
  backgroundColor: BORDER_COLOR
})

const Wrapper = styled(Box)({
  backgroundColor: BACKGROUND_COLOR
})

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1)
    .required('Required'),
  lastName: Yup.string()
    .min(1)
    .required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string()
    .email('Must be a valid email address')
    .required('Required'),
  appointmentReason: Yup.array()
    .min(1, "Pick at least 1 reason")
    .required("At least one checkbox is required"),
})

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #efefef',
      zIndex: 999
    },
    icon: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0
      }
    },
    textField: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
    },
    formControl: {
      // margin: theme.spacing(3),
    },
    container: {},
    link: {}
  })
interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: string;
  maskPlaceholder?: string;
  onChange?: any;
  value?: any;
}

interface submitBtnStatus {
  status: boolean;
}

function PhoneInputMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <ReactInputMask {...other} ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }} type="tel" mask="+1 (999) 999-9999" maskPlaceholder="DD/MM/YYYY" onChange={props.onChange} value={props.value} />
  );
}

function PostalCodeInputMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  return (
    <ReactInputMask {...other} ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }} mask="a9a 9a9" maskPlaceholder="A1A 1A1" onChange={props.onChange} value={props.value} />
  );
}

const ContentWrapper = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#f5f7fa',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(3)
  }
}))(Box)

const FormWrapper = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  }
}))(Paper)

const DarkenColor = Color('rgb(60, 137, 239)')
  .darken(0.1)
  .hsl()
  .toString()

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('rgb(60, 137, 239)'),
    color: '#3c8aef !important',
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(237,246,255, 0.5)',
    borderColor: 'rgba(60, 137, 239)',
    // boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    // fontFamily: [
    //   'Nunito',
    //   // 'Avenir'
    // ].join(','),
    // fontWeight: 600,
    '&:hover': {
      backgroundColor: 'rgba(72,177,251, 0.2)',
      borderColor: DarkenColor
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(237,246,255)',
      borderColor: DarkenColor
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  },
  text: {
    color: '#3c8aef'
  }
}))(Button)

export default withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {

  const phoneLabel = React.useRef<HTMLLabelElement>(null)
  const [phoneLabelWidth, setPhoneLabelWidth] = React.useState(0);

  const initialSubmitStatus: submitBtnStatus = {
    status: false
  }
  const [submitStatus, setSubmitStatus] = React.useState<submitBtnStatus | null>(initialSubmitStatus);

  React.useEffect(() => {
    setPhoneLabelWidth(phoneLabel.current!.offsetWidth);
  }, []);

  return (
    <Layout langKey="en">
      <SEO title="Request Appointment" />

      <Container maxWidth="md">
        <Box my={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={LinkComponent} color="inherit" to="/en">
              Home
            </Link>
            <Link component={LinkComponent} color="inherit" to="/en/resources/">
              Resources
            </Link>
            <Typography color="textPrimary">Forms</Typography>
          </Breadcrumbs>
        </Box>

        <HR />

        <Box mt={3}>
          <Typography variant="h4" component="h1">
            Request Appointment
          </Typography>

          <Box my={4}>
            <Typography>
              Please fill the form below and submit. We will get in touch with you shortly.
              Note that our office is closed on weekends and statuary holidays.
            </Typography>
          </Box>
        </Box>
      </Container>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          patientType: '',
          email: '',
          phone: '',
          appointmentReason: [],
          appointmentReasonDescription: '',
          preferredDay: {},
          preferredTime: {},
          preferredLocation: {},
        }}
        validateOnChange
        validationSchema={ValidationSchema}
        onSubmit={async (values, actions) => {
          // window.alert(JSON.stringify(values));
          console.log('*** values', JSON.stringify(values))
          setSubmitStatus({
            status: true
          })
          const formId = 'ccads2342423'
          let request = {
            service: 'execute-api',
            host: 'api.ari.dental',
            method: 'POST',
            url: 'https://api.ari.dental/appointform/submit',
            path: '/appointform/submit',
            data: {
              formId,
              values
            },
            body: JSON.stringify({ formId, values }),
            region: 'us-east-1',
            headers: {
              'Content-Type': 'application/json'
            }
          }

          const {
            secretAccessKey,
            accessKeyId,
            sessionToken
          } = await Auth.currentCredentials()

          let signedRequest = aws4.sign(request, {
            secretAccessKey: secretAccessKey,
            accessKeyId: accessKeyId,
            sessionToken: sessionToken
          })

          signedRequest.headers['Content-Type'] = 'application/json'

          delete signedRequest.headers['Host']
          delete signedRequest.headers['Content-Length']

          await axios(signedRequest)

          actions.setSubmitting(false)
          navigate('/en/resources/forms/form-success')
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched
        }) => {
          // console.log('*** values', JSON.stringify(values))
          // console.log('*** errorrs', JSON.stringify(errors));
          const hasError = (el) => (touched[el] && errors[el])

          return (
            <ContentWrapper>
              <Box pb={3}>
                <Container maxWidth="md">

                  <FormWrapper>

                    <Grid container spacing={3}>

                      <Grid item xs={12} md={6}>
                        <FormControl margin="normal" fullWidth={true} error={hasError('firstName')}>
                          <TextField
                            id="firstName"
                            label="First Name *"
                            className={classes.textField}
                            value={values.firstName}
                            variant="outlined"
                            onChange={handleChange}
                            fullWidth={true}
                            error={hasError('firstName')}
                          />
                          <FormHelperText>{hasError('firstName') && `${errors.firstName}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl margin="normal" fullWidth={true} error={hasError('lastName')}>
                          <TextField
                            id="lastName"
                            label="Last Name *"
                            className={classes.textField}
                            value={values.lastName}
                            variant="outlined"
                            onChange={handleChange}
                            fullWidth={true}
                            error={hasError('lastName')}
                          />
                          <FormHelperText>{hasError('lastName') && `${errors.lastName}`}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <HR />

                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <Box py={1}>
                            <FormLabel component="legend">
                              Are you New or Existing Patient? Please check below:
                          </FormLabel>
                          </Box>

                          <Box>
                            <RadioGroup
                              aria-label='patient type'
                              name="patientType"
                              value={values.patientType}
                              onChange={handleChange}
                              row={true}
                              onBlur={handleBlur}
                            >
                              <Grid item xs={12} md={3}>
                                <FormControlLabel
                                  labelPlacement="end"
                                  value="New Patient"
                                  control={<Radio />}
                                  label="New Patient"
                                />
                              </Grid>

                              <Grid item xs={12} md={3}>
                                <FormControlLabel
                                  labelPlacement="end"
                                  value="Existing Patient"
                                  control={<Radio />}
                                  label="Existing Patient"
                                />
                              </Grid>

                            </RadioGroup>
                          </Box>

                        </FormControl>
                      </Grid>

                      <HR />

                      <Grid item xs={12} md={6}>
                        <FormControl error={hasError('email')} margin="normal" fullWidth={true}>
                          <TextField
                            id="email"
                            label="Email *"
                            placeholder="email@example.com"
                            className={classes.textField}
                            value={values.email}
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth={true}
                            error={hasError('email')}
                          />
                          <FormHelperText>{hasError('email') && `${errors.email}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={12} md={6}>

                        <FormControl margin="normal" fullWidth={true} error={hasError('phone')}>
                          <InputLabel ref={phoneLabel} htmlFor="phone" variant='outlined'>Phone *</InputLabel>
                          <OutlinedInput
                            id="phone"
                            value={values.phone}
                            onChange={handleChange}
                            error={hasError('phone')}
                            inputComponent={PhoneInputMask}
                            fullWidth={true}
                            labelWidth={phoneLabelWidth}
                          />
                          <FormHelperText>{hasError('phone') && `${errors.phone}`}</FormHelperText>
                        </FormControl>

                      </Grid>

                      <HR />

                      <Box p={2} style={{ width: "100%" }}>
                        <FormControl fullWidth={true} error={hasError('appointmentReason')}>
                          <FormGroup>
                            <FormLabel>Please let us know the reason for this appointment *</FormLabel>
                            <FormHelperText>{hasError('appointmentReason') && `${errors.appointmentReason}`}</FormHelperText>
                            <Grid container>
                              <AppointmentReason
                                id="appointmentReason"
                                name="appointmentReason"
                                value={values.appointmentReason}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                              />
                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </Box>

                      {(values.appointmentReason.includes("Emergency Exam")
                        || values.appointmentReason.includes("Consult")
                        || values.appointmentReason.includes("Other")
                      ) && (
                          <Grid item xs={12}>
                            <TextField
                              id="appointmentReasonDescription"
                              label="Please Explain"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              fullWidth={true}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.appointmentReasonDescription}
                            />
                          </Grid>
                        )}

                      <HR />

                      <Box p={2} style={{ width: "100%" }}>
                        <FormControl fullWidth={true}>
                          <FormGroup>
                            <FormLabel component="legend">
                              Preferred day?
                          </FormLabel>
                            <Grid container>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredDay['Monday']}
                                      onChange={handleChange}
                                      name="preferredDay.Monday"
                                    />
                                  }
                                  label="Monday"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredDay['Tuesday']}
                                      onChange={handleChange}
                                      name="preferredDay.Tuesday"
                                    />
                                  }
                                  label="Tuesday"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredDay['Wednesday']}
                                      onChange={handleChange}
                                      name="preferredDay.Wednesday"
                                    />
                                  }
                                  label="Wednesday"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredDay['Thurday']}
                                      onChange={handleChange}
                                      name="preferredDay.Thursday"
                                    />
                                  }
                                  label="Thursday"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredDay['Friday']}
                                      onChange={handleChange}
                                      name="preferredDay.Friday"
                                    />
                                  }
                                  label="Friday"
                                />

                              </Grid>

                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </Box>

                      <HR />

                      <Box p={2} style={{ width: "100%" }}>
                        <FormControl fullWidth={true}>
                          <FormGroup>
                            <FormLabel component="legend">
                              Preferred time?
                          </FormLabel>
                            <Grid container>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredTime['8-10 am']}
                                      onChange={handleChange}
                                      name="preferredTime.8-10 am"
                                    />
                                  }
                                  label="8-10 am"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredTime['10-12 am']}
                                      onChange={handleChange}
                                      name="preferredTime.10-12 am"
                                    />
                                  }
                                  label="10-12 am"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredTime['12-2 pm']}
                                      onChange={handleChange}
                                      name="preferredTime.12-2 pm"
                                    />
                                  }
                                  label="12-2 pm"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredTime['2-4 pm']}
                                      onChange={handleChange}
                                      name="preferredTime.2-4 pm"
                                    />
                                  }
                                  label="2-4 pm"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredTime['4-6 pm']}
                                      onChange={handleChange}
                                      name="preferredTime.4-6 pm"
                                    />
                                  }
                                  label="4-6 pm"
                                />

                              </Grid>

                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </Box>

                      <HR />

                      <Box p={2} style={{ width: "100%" }}>
                        <FormControl fullWidth={true}>
                          <FormGroup>
                            <FormLabel component="legend">
                              Preferred location?
                          </FormLabel>
                            <Grid container>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredLocation['451 King West']}
                                      onChange={handleChange}
                                      name="preferredLocation.451 King West"
                                    />
                                  }
                                  label="451 King West"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredLocation['46 John St']}
                                      onChange={handleChange}
                                      name="preferredLocation.46 John St"
                                    />
                                  }
                                  label="46 John St"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.preferredLocation['Either Locations']}
                                      onChange={handleChange}
                                      name="preferredLocation.Either Locations"
                                    />
                                  }
                                  label="Either Locations"
                                />

                              </Grid>

                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </Box>

                      <HR />

                      <Box p={2}>

                        <BlueButton
                          onClick={(e) => {
                            e.preventDefault()
                            handleSubmit(e)
                          }}
                          disabled={submitStatus.status}
                        >
                          Submit&nbsp;&nbsp;
                        {submitStatus.status && <CircularProgress color="inherit" size={25} thickness={2} />}
                        </BlueButton>
                      </Box>
                    </Grid>
                    <ErrorFocus />
                  </FormWrapper>
                </Container>
              </Box>
            </ContentWrapper>
          )
        }}
      />
    </Layout>
  )
})

/*
    I, the undersigned, certify that I have provided an accurate and
    complete personal and medical - dental history and have not
    knowingly omitted any information. I have had the opportunity to
    ask questions and receive answers to any questions regarding my
    medical - dental history. Should there be any change in my
    health status in the future, I will advise this dental office. I
    authorize the dentist to perform diagnostic procedures as may be
    required to determine necessary treatment. I understand that
    information provided from or to my medical doctor or an4-6 pm
    health care provider may be necessary. I understand that
    responsibility for payment of the dental services for myself and
    my dependents is mine, and I assume responsibility for fees
    associated with these services.I understand that 48 hours notice
    is required for changing or cancellation of my appointments,
    4-6 pmwise there is $50 charge per hour that may be apply.
*/

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export const AppointmentReason = (props) => {
  const options = [
    {"value": "New Patient Exam", "label": "New Patient Exam"},
    {"value": "Emergency Exam", "label": "Emergency Exam"},
    {"value": "Consult", "label": "Consult"},
    {"value": "Check up and cleaning", "label": "Check up and cleaning"},
    {"value": "Other", "label": "Other"},
  ]

  const handleChange = val => {
    let valueArray = [...props.value] || [];
    if (!valueArray.includes(val)) {
      valueArray.push(val);
    } else {
      valueArray.splice(valueArray.indexOf(val), 1);
    }
    props.onChange(props.name, valueArray);
  };

  const handleBlur = () => {
    props.onBlur(props.name, true);
  };

  const { name, value } = props;

  return (
      <React.Fragment>
          { options.map((option, index) => {
              return (
                  <Grid item xs={12} md={4}>
                      <FormControlLabel
                          control={
                              <Checkbox
                                  name={name}
                                  onChange={() => handleChange(option.value)}
                                  onBlur={handleBlur}
                              />
                          }
                          label={option.label}
                      />
                  </Grid>
              )
          })} 
      </React.Fragment>
  )
}

export default AppointmentReason;